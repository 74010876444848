
.AccountAudit {
  height: calc(100vh - 100px);
  background: #ffffff;
  display: flex;
  flex-direction: column;

  .AccountAuditHeader {
    color: #333333;
    font-size: 16px;
    line-height: 55px;
    border-bottom: 1px solid #F3F3F6;
    padding-left: 60px;
  }

  .selectClass {
    display: flex;
    padding: 27px 60px;
  }

}

::v-deep .pages-center {
  padding: 30px 0;
}

.el-table::before {
  display: none;
}
::v-deep .el-dialog__body{
  padding: 15px !important;
}
